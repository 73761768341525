import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from "react-router-dom"

const UserLedger = () => {
    const backUrl = process.env.REACT_APP_URL;
    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const editProduct = (e, id) => {
        e.preventDefault();
        navigate(`/edit/deal/${id}`);
    };

    useEffect(() => {
        axios.get(`${backUrl}/admin/alluser`)
            .then((res) => {
                console.log(res, "ALL user")
                setData(res.data);


            })
            .catch((err) => {
                console.error(err);
            });
    }, [backUrl]); // Added dependency array to avoid repeated API calls


    useEffect(() => {
        const Email = localStorage.getItem('Email');  // get name of cookies
        console.log(Email, "email recieved from localstorage");
        if (Email == null) {
            console.log("sfj;osadjf")
            navigate('/')
        }
    }, [])


    return (
        <div className="border">
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 DARK:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 DARK:bg-gray-700 DARK:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">User ID </th>
                            <th scope="col" className="px-6 py-3">User Name</th>
                            <th scope="col" className="px-6 py-3">Payable Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item._id} className="bg-white border-b DARK:bg-gray-800 DARK:border-gray-700">
                                <Link to={`/user-ledger/${item?.Email}`} className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap DARK:text-white">{item?.Email ? item?.Email : item?.Phoneno}</Link>
                                <td className="px-6 py-4">{(item?.first_Name) + (item?.last_Name)}</td>
                                <td className="px-6 py-4">{item?.Wallet}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserLedger;
